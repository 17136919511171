<template>
    <div class="main-detail">
        <div style="width:100%;position:relative;">
            <Header></Header>
            <img class="main-detail-bg" src="../../assets/imgs/bg2.png" alt="">
            <div class="main_centerbox">
                <div class="position_box">
                    <span class="span">当前位置：</span>
                    <el-breadcrumb separator-class="el-icon-arrow-right" style="display: inline-block;height: 35px;line-height: 35px;float: left;">
                        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{parentTitle}}</el-breadcrumb-item>
                        <el-breadcrumb-item v-if="curTitle">{{curTitle}}</el-breadcrumb-item> -->
                        <el-breadcrumb-item v-for="(item,i) in curTitle" :key="i">{{item}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="main_detail_content" :text="content.contentTitle">
                    <h4 v-show="iftitle == 2" class="main_detail_content_h4">{{content.contentTitle}}</h4>
                    <div class="main_detail_content_under_h4">
                        <span v-show="iftitle == 2 && content.contentKeyword != 'nodate'">发布时间：{{content.contentDatetime ? content.contentDatetime.substring(0,10) : ''}}</span>
                        <span v-if="content.contentAuthor" v-show="iftitle == 2">作者：{{content.contentAuthor}}</span>
                        <span v-if="content.contentSource" v-show="iftitle == 2">来源：{{content.contentSource}}</span>
                    </div>
                    <!-- <img class="main_detail_content_img" v-show="JSON.parse(content.contentImg).length > 0" v-for="(item,i) in JSON.parse(content.contentImg)" :key="i" :src="imgBase + item.path" :alt="content.contentTitle"> -->
                    <p class="main_detail_content_p" v-html="content.contentDetails"></p>
                    <el-empty v-if="ifEmpty" description="暂无数据!"></el-empty>
                    <div v-if="content.fileInfos && content.fileInfos.length > 1" class="main_detail_download_box">
                        <a v-for="(item,i) in content.fileInfos" :key="i" :href="'/ms/file/getimage/' + item.downLoadId" :title="item.fileName" target="_blank">
                            <img src="../../assets/imgs/download.png" alt="">
                            附件_{{(i + 1)+ ':' +item.fileName}}
                        </a>
                    </div>
                    <div v-if="content.fileInfos && content.fileInfos.length == 1" class="main_detail_download_box2">
                        <a class="main_detail_download_box_btn1" v-for="(item,i) in content.fileInfos" :key="i" :href="'/ms/file/getimage/' + item.downLoadId" :title="item.fileName" target="_blank">
                            <img src="../../assets/imgs/load2.png" alt="">
                            附件预览
                        </a>
                        <a class="main_detail_download_box_btn2" v-for="(item,i) in content.fileInfos" :key="i" :href="'/ms/file/getimage/' + item.downLoadId" :title="item.fileName" :download="item.fileName">
                            <img src="../../assets/imgs/load1.png" alt="">
                            附件下载
                        </a>
                    </div>
                </div>
                
            </div>
            <Footer></Footer>
        </div>
        <!-- 回到顶部 -->
        <el-backtop target=".main-detail" :bottom="100">
            <!-- <div
                style="{
                    height: 100%;
                    width: 100%;
                    background-color: #f2f5f6;
                    box-shadow: 0 0 6px rgba(0,0,0, .12);
                    text-align: center;
                    line-height: 40px;
                    color: #1989fa;
                }"
            >
                UP
            </div> -->
            <i class="el-icon-caret-top"></i>
        </el-backtop>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'

export default {
    name: 'communicate',
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            content: {},
            curTitle: [],
            parentTitle: '',
            ifEmpty: false,
            iftitle:2,//是否显示title
        }
    },
    components:{
        Header,
        Footer,
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData(){
            let _this = this;
            
            let docId = this.getQueryString('docId');
            let title = this.getQueryString('title');
            let ptitle = this.getQueryString('ptitle');
            // this.getQueryString('iftitle') ? _this.iftitle = 1 : '';
            console.log(_this.iftitle,'iftitle')
            // this.curTitle = title;
            // this.parentTitle = ptitle;
            if(docId){
                this.ifEmpty = false;
                api.getArticleDetail(docId).then(res => {
                    if (res.data) {
                        _this.content = res.data;
                        document.title = res.data.contentTitle + ' | 知识中心'
                        console.log(res.data,'res.data');
                        // $('.main_detail_content_p').html(res.data.contentDetails);
                        !res.data.contentDetails ? _this.ifEmpty = true : _this.ifEmpty = false;
                        _this.curTitle = res.data.categoryPosition;
                        if(res.data.categoryPosition.includes('中⼼领导') || res.data.categoryPosition.includes('专家团队') || res.data.categoryPosition.includes('历任领导')){
                            _this.iftitle = 1;
                        }

                        let imgs =  res.data.contentImg ? JSON.parse(res.data.contentImg) : [];

                        if(imgs.length > 0){
                            setTimeout(()=>{
                                $('.main_detail_content_p video').attr('preload',true);
                                $('.main_detail_content_p video').attr('poster',_this.imgBase + imgs[0].path);
                            },300);
                        }
                    }
                });
            }else{
                this.ifEmpty = true;
            }
            console.log(this.ifEmpty,'this.ifEmpty');
        },
        //获取URL参数
        getQueryString(key){
            var reg = new RegExp("(^|&)"+key+"=([^&]*)(&|$)");
            var result = window.location.search.substr(1).match(reg);
            return result?decodeURIComponent(result[2]):null;
        },
        //点击下载文件函数
        downloadFiles(){

        }
    }
}
</script>

<style scoped>
.main-detail{
    width:100%;
    height: 100vh;
    position: relative;
    overflow-x:hidden;
}
.main-detail-bg{
    position: absolute;
    bottom:273px;
    left:0;
    z-index: -1;
}
.main_centerbox{
    width: 1200px;
    margin: 28px auto 0;
    overflow: hidden;
}
.main_detail_content_h4{
    width:100%;
    /* height: 22px; */
    font-size: 22px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    text-align: center;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    overflow: hidden;
}
.main_detail_content_under_h4{
    width: 100%;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #333333;
    line-height: 29px;
    text-align: center;
    margin-bottom:25px;
    margin-top:23px;
}
.main_detail_content_under_h4 span{
    margin-right:28px;
}
.position_box{
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #F9F9F9;
}
.position_box .span{
    height: 35px;
    line-height: 35px;
    display: inline-block;
    float: left;
    padding-left: 12px;
    margin-right:10px;
}
.main_detail_content{
    margin-bottom: 100px;
    margin-top: 50px;
    background: #fff;
    padding:40px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 0px rgb(223 226 230 / 75%);
}
.main_detail_content_img{
    width: 80%;
    margin: 0 auto 50px;
    display: block;
}
.main_detail_content_p{
    /* width: 80%; */
    margin: 0 auto;
    line-height: 3;
    text-align: left;
}
.main_detail_download_box{
    width:100%;
    text-align: left;
    font-size: 18px;
    color:#2164C8;
}
.main_detail_download_box a{
    color:#2164C8;
    display:block;
    width:100%;
    text-align: left;
    margin-bottom:17px;
}
.main_detail_download_box a img{
    display: inline-block;
    width: 18px;
    margin-right: 6px;
}
.main_detail_download_box2{
    width:100%;
    text-align: center;
    font-size: 18px;
    color:#2164C8;
    margin-top: 45px;
}
.main_detail_download_box2 a{
    color:#000;
    display:inline-block;
    width:150px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    margin-bottom:17px;
    border-radius: 10px;
    border:1px solid #9E9E9E;
}
.main_detail_download_box2 a:nth-child(2){
    background: linear-gradient(0deg, #52a1ea, #90c3f9);
    border:none;
    color:#fff;
    margin-left: 25px;
}  
.main_detail_download_box2 a img{
    display: inline-block;
    width: 18px;
    margin-right: 6px;
    float: left;
    margin-top: 20px;
    margin-left: 24px;
}
.main_detail_download_box2 a:nth-child(2) img{
    margin-top: 15px;
}






</style>
